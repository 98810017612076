import React from 'react'

import { LoadingIndicator } from './style'

interface ILoadingIndicator {
  size?: string;
}

const LoadingIndicatorComponent: React.FC<ILoadingIndicator> = ({ size }: ILoadingIndicator) => {
  return (
    <LoadingIndicator size={size} width='36' height='36' viewBox='0 0 36 36'>
      <path d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831' fill='none' />
    </LoadingIndicator>
  )
}

LoadingIndicatorComponent.defaultProps = {
  size: 'default',
}

export default LoadingIndicatorComponent
