import styled, { keyframes, css } from 'styled-components'
import { orange } from '../../styles/colors'

const x = keyframes`
  0% {
    transform: rotate(0deg);
    stroke-dasharray: 10 100;
  }
  50% {
    stroke-dashoffset: 50;
    stroke-dasharray: 100 100;
  }
  100% {
    transform: rotate(360deg);
    stroke-dasharray: 10 100;
  }
`

interface ILoadingIndicator {
  size?: string;
}

export const LoadingIndicator = styled.svg<ILoadingIndicator>`
  animation: ${x} 1s linear infinite;
  stroke: ${orange.base};
  stroke-width: 3;

  ${(props: ILoadingIndicator) => {
    switch (props.size) {
      case 'small':
        return css`
          width: 22px;
          height: 22px;
        `
      case 'default':
        return css`
          width: 36px;
          height: 36px;
        `
      case 'large':
        return css`
          width: 48px;
          height: 48px;
        `
    }
  }}
`
